import { render, staticRenderFns } from "./EditaClasse12.vue?vue&type=template&id=49eff5e6&scoped=true&"
import script from "./EditaClasse12.vue?vue&type=script&lang=js&"
export * from "./EditaClasse12.vue?vue&type=script&lang=js&"
import style0 from "./EditaClasse12.vue?vue&type=style&index=0&id=49eff5e6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49eff5e6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VCard,VCol,VExpansionPanels,VRow,VSnackbar})
